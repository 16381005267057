<template>
  <NuxtLink
    :to="
      !core.isLoggedIn || showInputs || selectable
        ? undefined
        : functionRoute(fn.functionId)
    "
    :class="mainClasses"
    @click="handleClick"
  >
    <div
      :class="`relative rounded-md px-4 bg-foundation h-28 flex items-center space-x-3 shadow hover:shadow-md transition hover:ring overflow-hidden ${
        selected ? 'ring shadow-md' : ''
      }`"
    >
      <div
        v-if="hasTag"
        class="absolute top-0 right-0 rounded-bl-lg text-xs px-2 py-1 text-foreground-on-primary"
        :class="{ 'bg-primary': isFeatured, 'bg-warning': isOutdated }"
      >
        <template v-if="isOutdated">outdated</template>
        <template v-else-if="isFeatured">featured</template>
      </div>
      <div
        class="bg-blue-500/10 text-primary font-bold h-10 w-10 rounded-full shrink-0 flex justify-center text-center items-center overflow-hidden !ml-0"
      >
        <img v-if="logo" :src="logo" alt="function logo" />
        <span v-else>λ</span>
      </div>
      <div class="max-w-full overflow-hidden">
        <div class="font-bold truncate overflow-hidden">
          {{ fn.functionName }}
        </div>
        <div class="text-sm text-foreground-2 truncate">
          {{
            !description.length || description.toLowerCase().includes('fixme: missing')
              ? 'No description provided.'
              : description
          }}
        </div>
        <div class="text-xs text-foreground-2 italic">
          <template v-if="automationCount">Used {{ automationCount }} times,</template>
          Updated {{ lastPublished }}
        </div>
      </div>
      <div
        v-if="showInputs"
        class="group-hover:opacity-100 opacity-0 bg-white/10 backdrop-blur-sm transition absolute -left-3 top-0 w-full h-full flex items-center justify-center"
        role="button"
        tabindex="0"
        @click="emit('inputs-click')"
        @keypress="keyboardClick(() => emit('inputs-click'))"
      >
        <FormButton>View and Edit Details</FormButton>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { formatTimeAgo } from '@vueuse/core'
import { keyboardClick } from '~/lib/common/core/helpers/accessibility'
import { functionRoute } from '~/lib/common/core/helpers/route'
import { cleanFunctionLogo } from '~/lib/common/functions/helpers/index'
import { useTriggerLogin } from '~/lib/frontend/auth/composables/auth'
import type { GetAutomationItem } from '~/lib/frontend/automations/composables/management'
import { useMarkdown } from '~/lib/frontend/common/composables/markdown'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import type { GetFunctionsItem } from '~/lib/frontend/functions/composables/management'

const core = useCoreStore()
const startAuthFlow = useTriggerLogin()

type BaseFnType = GetFunctionsItem
type AutomationFnType =
  GetAutomationItem['automationVersions'][number]['functions'][number]['functionMain']
type FunctionCardItem = BaseFnType | AutomationFnType

const isAutomationFnType = (v: FunctionCardItem): v is AutomationFnType =>
  'latestVersion' in v

const emit = defineEmits<{
  (e: 'click' | 'inputs-click'): void
  (e: 'update:selected', val: boolean): void
}>()

const props = defineProps<{
  fn: FunctionCardItem
  showInputs?: boolean
  usedVersion?: string
  selectable?: boolean
  selected?: boolean // TODO: use
  checkForUpdates?: boolean
}>()

const isFeatured = computed(() => props.fn.isFeatured)
const isOutdated = computed(() => {
  if (!isAutomationFnType(props.fn)) return false

  const fnVersionId = props.usedVersion
  const latestVersionId = props.fn.latestVersion?.functionVersionId
  if (!fnVersionId || !latestVersionId) return false

  return fnVersionId !== latestVersionId
})
const hasTag = computed(() => isFeatured.value || isOutdated.value)
const { html: description } = useMarkdown(
  computed(() => props.fn.description || ''),
  { plaintext: true, key: 'FunctionsCardMarketplace' }
)

const automationCount = computed(() => {
  if (!isAutomationFnType(props.fn)) return props.fn.automationCount
  else return undefined
})

const lastPublished = computed(() => {
  if (isAutomationFnType(props.fn)) {
    if (!props.fn.latestVersion) return 'never'
    else return formatTimeAgo(new Date(props.fn.latestVersion.createdAt))
  } else {
    if (!props.fn.functionVersions) return 'never'
    if (props.fn.functionVersions?.length === 0) return 'never'
    else
      return formatTimeAgo(new Date(props.fn.functionVersions[0]?.createdAt as string))
  }
})

const logo = computed(() => cleanFunctionLogo(props.fn.logo))

const mainClasses = computed(() => {
  const classParts = ['group relative block']

  if (!props.showInputs) {
    classParts.push('cursor-pointer')
  }
  return classParts.join(' ')
})

const handleClick = (e: MouseEvent) => {
  if (props.selectable) {
    emit('update:selected', true)
    e.preventDefault()
    return
  }

  if (!core.isLoggedIn) {
    startAuthFlow()
    e.preventDefault()
    return
  }
}
</script>
